.table-responsive > .table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  vertical-align: middle;
}
.table > tbody > tr > td:first-child {
  width: 28% !important;
  padding: 15px !important;
}
.table > tbody > tr > td {
  padding: 15px !important;
  vertical-align: middle;
}

.card {
  padding: 0px !important;
}

tbody tr th {
  font-style: normal;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 1000px) {
  .table > tbody > tr > td:first-child {
    width: 35% !important;
    padding: 15px !important;
  }
  .table {
    width: 100% !important;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .table > tbody > tr > td:first-child {
    width: 35% !important;
    padding: 15px !important;
  }
  .table {
    width: 100% !important;
  }
}
