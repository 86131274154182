.table-responsive > .table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  vertical-align: middle;
}
.table > tbody > tr > td:first-child {
  width: 28% !important;
  padding: 15px !important;
}
.table > tbody > tr > td {
  padding: 15px !important;
  vertical-align: middle;
}

.card {
  padding: 0px !important;
}

.pageContainer {
  font-size: 15px !important;
}
.card_Img {
  display: none;
}
.card_style {
  padding: 25px !important;
}
.card_style_blue {
  padding: 25px !important;
  background: #0f5c7a !important;
  color: #fff !important;
}
.card_style button {
  background: #0481b5;
  color: white;
  text-transform: uppercase;
  border: none;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  width: 30%;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  margin-bottom: 10px;
  min-width: 200px;
}
.tableh4 {
  margin: 10px 0;
  padding: 14px 0;
  text-align: center;
}
.tableh4:hover {
  background-color: #ececec;
}
/* .section_blue_line{
    border-bottom: solid 3px #00AAE4;
} */

.round_image_border {
  border: solid 15px #083f884f;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 13px;
  right: 13px;
}

.reason_table th {
  font-weight: normal;
}
.reason_table thead th {
  font-weight: bold;
  font-size: 20px;
  vertical-align: middle !important;
}
.reason_table td {
  font-weight: 600;
  vertical-align: middle !important;
}

.reason_table td,
.reason_table th,
.reason_table thead th {
  border: none;
  padding-left: 0%;
}
.stateh2 {
  text-align: center;
}

@media (max-width: 768px) {
  .stateh2 {
    font-size: 20px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .table > tbody > tr > td:first-child {
    width: 35% !important;
    padding: 15px !important;
  }
  .table {
    width: 100% !important;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .table > tbody > tr > td:first-child {
    width: 35% !important;
    padding: 15px !important;
  }
  .table {
    width: 100% !important;
  }
}
@media (max-width: 450px) {
  .sm-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}
