.navbar {
  background-color: bisque;
}

.text_blue_XL {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #496273;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust the z-index as needed */
  background-color: white; /* Add a background color if needed */
}
.marco_logo{
  padding-top: 1rem;
}

.fixated,
.nav-logo,
.footer-content {
  margin-top: 1%;
  margin-bottom: 1%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
@media(max-width:991px){
    .fixated,
.nav-logo,
.footer-content {
  margin-top: 1%;
  margin-bottom: 1%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.nav-logo {
  display: flex;
  justify-content: center;
}
}

@media (max-width: 450px) {
  .sm-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}
